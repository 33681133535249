import React from 'react';
import {
  TFunction,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { DurationDataHooks } from './dataHooks';
import { TagName } from '../../../../utils/tagName.const';
import {
  getCourseTotalNumberOfSessionsDurationText,
  getFormattedDuration,
} from '../../../../utils/duration/duration';
import Text, { TextType } from '../../Text/Text';
import { Slot } from '@wix/ambassador-availability-calendar/types';

export interface DurationProps {
  totalNumberOfSessions: number;
  slot: Slot;
}

const Duration: React.FC<DurationProps> = ({ totalNumberOfSessions, slot }) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const isFixDurationOver24HoursEnabled = experiments.enabled(
    'specs.bookings.FixDurationOver24Hours',
  );
  const { startDate, endDate } = slot;
  const duration = getDuration({
    totalNumberOfSessions,
    startDate: startDate!,
    endDate: endDate!,
    t,
    isFixDurationOver24HoursEnabled,
  });

  return (
    <Text
      type={TextType.Secondary}
      data-hook={DurationDataHooks.DURATION}
      tagName={TagName.Paragraph}
      aria-label={duration.durationAriaText}
    >
      {duration.durationText}
    </Text>
  );
};

const getDuration = ({
  totalNumberOfSessions,
  startDate,
  endDate,
  t,
  isFixDurationOver24HoursEnabled,
}: {
  totalNumberOfSessions: number;
  startDate: string;
  endDate: string;
  t: TFunction;
  isFixDurationOver24HoursEnabled: boolean;
}) => {
  return totalNumberOfSessions > 1
    ? getCourseTotalNumberOfSessionsDurationText(totalNumberOfSessions, t)
    : getFormattedDuration({
        startDate,
        endDate,
        t,
        isFixDurationOver24HoursEnabled,
      });
};
export default Duration;
